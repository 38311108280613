import {
  ComponentPropsWithoutRef,
  CSSProperties,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import { useState } from 'react';
import { useIsMounted } from '@toss/react';
import { useBody, useViewportDevice } from '@zep/hooks';
import { IconContainer, XIcon } from '@zep/icons';
import { cn, zepAnalytics } from '@zep/utils';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const Key = 'HideStickyBanner';
import styles from './StickyBanner.module.scss';
const PathAliasMap: Record<string, string> = {
  '/': 'home',
  '/public': 'public',
  '/search': 'search',
  '/quiz': 'listItem',
  '/quiz/[id]': 'detail',
  '/build': 'create',
  '/update/[id]': 'update',
  '/duplicate/[id]': 'duplicate',
  unknown: 'unknown',
};
export const StickyBanner = () => {
  const { pathname } = useRouter();
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();
  const isKoLocale = String(resolvedLanguage).includes('ko');
  const [isShow, setIsShow] = useState(true);
  const isMounted = useIsMounted();
  const isHide = getCookie(Key);

  const goCTALink = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    zepAnalytics.track('banner_click', {
      location: PathAliasMap[String(pathname)] || PathAliasMap.unknown,
    });
    window.open('https://tally.so/r/wMY1jX', '_blank');
  };

  const hideBanner = (e: MouseEvent) => {
    e.stopPropagation();
    setCookie('HideStickyBanner', true, {
      maxAge: 10 * 60,
    });
    setIsShow(false);
  };

  useBody(
    {
      attributes: [
        {
          name: 'data-is-stick-banner-show',
          value: isShow && !isHide && isKoLocale ? 'true' : 'false',
        },
      ],
    },
    [isShow, isHide, isKoLocale],
  );

  if (!isKoLocale || !isShow || !isMounted || isHide) return null;

  return (
    <Wrapper onClick={goCTALink}>
      <Container>
        <CTA />
        <div className={'absolute right-0'}>
          <IconContainer onClick={hideBanner}>
            <XIcon
              style={{ position: 'absolute', right: 18 }}
              fill={'#5D5E69'}
              width={24}
              height={24}
            />
          </IconContainer>
        </div>
      </Container>
    </Wrapper>
  );
};

const Container = (props: ComponentPropsWithoutRef<'div'>) => {
  const { isMobile } = useViewportDevice();

  return (
    <div
      className={`${
        isMobile ? 'w-full' : 'w-[90%]'
      } relative flex max-w-[960px] items-center justify-center`}>
      {props.children}
    </div>
  );
};

const CTA = (props: ComponentPropsWithoutRef<'div'>) => {
  const { isMobile } = useViewportDevice();
  const imageSrc = '/assets/sticky_banner_3.webp';

  if (!isMobile) {
    return (
      <div {...props} className={'absolute w-[624px] cursor-pointer'}>
        <img width={'100%'} height={80} src={imageSrc} alt="banner" />
      </div>
    );
  }
  return (
    <div className={'flex size-full items-center justify-center'} {...props}>
      <img className={'size-full'} src={imageSrc} alt="banner" />
    </div>
  );
};

const Wrapper = (props: ComponentPropsWithoutRef<'div'>) => {
  const { isMobile } = useViewportDevice();
  const background = `linear-gradient(90deg, #FBFBFF 0%, #E7EFFF 52%, #FBFBFF 100%)`;

  if (!isMobile) {
    return (
      <div
        {...props}
        className={
          'fixed top-0 z-[50] flex h-[80px] w-[100vw] cursor-pointer items-center justify-center gap-[135px]'
        }
        style={{
          background,
        }}>
        {props.children}
      </div>
    );
  }
  return (
    <div
      {...props}
      className={
        'w-max-[960px] fixed top-0 z-[50] flex h-[64px] w-full cursor-pointer items-center justify-center'
      }
      style={{
        background,
      }}
    />
  );
};

export const BannerSpacer = (
  props: PropsWithChildren<{
    headerHeight: {
      mobile: number;
      normal: number;
    };
    className?: string;
  }>,
) => {
  return (
    <div
      className={cn(styles.wrapper, props.className)}
      style={
        {
          '--header-height': props.headerHeight.normal + 'px',
          '--header-mobile-height': props.headerHeight.mobile + 'px',
        } as CSSProperties
      }>
      {props.children}
    </div>
  );
};
