import type { SVGProps } from 'react';
import * as React from 'react';

type Size = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}

const getDimension = (size: Size) => {
  switch (size) {
    case '2xs':
      return 14;
    case 'xs':
      return 16;
    case 'sm':
      return 20;
    case 'md':
      return 24;
    case 'lg':
      return 28;
    case 'xl':
      return 32;
  }
};

const ZepIcon = (
  IconComponent: (props: SVGProps<SVGSVGElement>) => React.JSX.Element,
) => {
  const Icon = (props: Props) => (
    <IconComponent
      {...props}
      width={props.size ? getDimension(props.size) : props.width ?? 24}
      height={props.size ? getDimension(props.size) : props.height ?? 24}
    />
  );
  return Icon;
};

export default ZepIcon;
