import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useViewportDevice } from '@zep/hooks';
import { cn, zepAnalytics } from '@zep/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogPanel,
  DialogTitle,
} from '../ui/dialog';

type ShareModalProps = {
  entryMapHashId: string;
  title: string;
  enterCode: number;
  spaceHashId: string;
  hideAction?: boolean;
  quizSetId: string;
};

function _ShareModal(props: ShareModalProps) {
  const { locale } = useRouter();
  const modal = useModal();
  const { isPortableDevice } = useViewportDevice();
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const shareUrl = `${window.location.origin}/play/${props.entryMapHashId}`;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <Dialog onClick={modal.remove}>
      <DialogPanel className="max-w-[720px]">
        <DialogHeader onClose={modal.remove}>
          <DialogTitle>{props.title}</DialogTitle>
        </DialogHeader>
        <DialogContent className="flex flex-col items-center">
          <div className="mb-md w-full rounded border-black/[0.03] bg-[#f8f9fc] px-[24px] py-[10px] text-center">
            <div className="text-[14px] font-bold">{t('shareModal.title')}</div>
            <div className="text-[40px] font-extrabold leading-[1.4] text-primary">
              {props.enterCode}
            </div>
          </div>
          <div className="mx-auto my-md h-auto w-[80%] max-w-[320px]">
            <QRCode
              size={isPortableDevice ? 400 : 512}
              className="h-auto w-full max-w-full"
              value={shareUrl}
            />
          </div>

          <CopyToClipboard
            text={shareUrl}
            onCopy={() => {
              zepAnalytics.track('share_link_copy', {
                link: shareUrl,
              });
              setCopied(true);
            }}>
            <div
              className={cn(
                'cursor-pointer inline-flex items-center mt-md py-[13px] px-md rounded-full',
                copied ? 'bg-[#05b8a3]/[0.1]' : 'bg-[#f3f2ff]',
              )}>
              <img
                src={
                  copied
                    ? '/assets/icon_check_green.svg'
                    : '/assets/icon_link_primary.svg'
                }
                alt="link copy"
              />
              <div
                className={cn(
                  'ml-3xs font-bold',
                  copied ? 'text-[#15e42a]' : 'text-primary',
                )}>
                {copied
                  ? t('shareModal.complete')
                  : t('shareModal.copyLink.button')}
              </div>
            </div>
          </CopyToClipboard>
        </DialogContent>

        {!props.hideAction && (
          <DialogFooter className="flex-row-reverse">
            <Link
              href={shareUrl}
              target="_blank"
              onClick={() => {
                zepAnalytics.track('enter_space', {
                  locale,
                });

                zepAnalytics.track('enter_play', {
                  location: 'shareModal',
                  spaceId: props.spaceHashId,
                  isAuthor: true,
                });
              }}
              className="cursor-pointer rounded bg-primary px-md py-[13px] font-semibold text-white">
              {t('global.enter.button')}
            </Link>
            <div
              className="cursor-pointer px-[30px] py-[13px] font-semibold text-gray-600"
              onClick={modal.remove}>
              {t('global.close.button')}
            </div>
          </DialogFooter>
        )}
      </DialogPanel>
    </Dialog>
  );
}

export const ShareModal = NiceModal.create(_ShareModal);

export const useShareModal = () => {
  const modal = useModal(ShareModal);

  return (payload: ShareModalProps & { trackLocation: string }) => {
    zepAnalytics.track('enter_share', {
      location: payload.trackLocation,
      quizSetId: payload.quizSetId,
    });

    return modal.show(payload);
  };
};
